body{
  background-color: #3e403ec7 !important;
}

.card {
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  background: #27232385 !important;
}
.expense-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 12px;
  background-color: #4b4b4b;
}

.expense-item__description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  /*flex-flow: column-reverse;*/
  justify-content: flex-start;
  flex: 1;
}

.expense-item h2 {
  color: #3a3a3a;
  font-size: 1rem;
  flex: 1;
  margin: 0 1rem;
  margin-right: 0;
  color: white;
}

.expense-item__price {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #40005d;
  border: 1px solid white;
  padding: 0.5rem;
  border-radius: 12px;
}

.expense-item__delete {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #9f1212;
  border: 1px solid white;
  padding: 0.7rem 0.6rem;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
  max-width: 70px;
  margin-right: 10px;
}

.expense-item__edit {
  font-size: 1rem;
  font-weight: bold;
  color: rgb(54, 38, 38);
  background-color: #d0ff7b;
  border: 1px solid white;
  padding: 0.7rem 0.6rem;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
  max-width: 70px;
  text-align: center;
}

.expense-item__close {
  font-size: 1rem;
  font-weight: bold;
  color: rgb(54, 38, 38);
  background-color: #d79e86;
  border: 1px solid white;
  padding: 0.7rem 0.6rem;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
  max-width: 70px;
  text-align: center;
  margin-left: 5px;
}

.expense-item__action {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #129f6496;
  border: 1px solid white;
  padding: 0.7rem 0.5rem;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
  max-width: 70px;
  margin-right: 10px;
}

.heading{
  text-align: center;
  font-size: 3.5em;
  color: azure;
  text-decoration: underline;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: #2e2e2e 1px 2px 10px;

}

.expense__no-expense {
text-align: center;
padding: 20px;
color: #f7a6a6;;
font-weight: bold;
font-size: larger;
}

.actions{
  display: flex;
  /*flex-wrap: wrap;*/
  align-items: center;
  max-width: 200px;
  width: 100%;
}


#alert, #alertHuge{
  width: fit-content;
margin: 0px auto;
}

@media (min-width: 580px) {
  .expense-item__description {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  .expense-item__description h2 {
    font-size: 1.25rem;
  }

  .expense-item__price {
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem;
  }

  .expense-item__delete {
    font-size: 1rem;
    /* padding: 0.5rem 1.5rem; */
  }


}